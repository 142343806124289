import React, {Component} from "react";
import {Icon, Input, Progress} from "semantic-ui-react";
import Helper from "../helper/Generator";

class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            value: this.props.value,
            visible: false,
            strength: 0,
        };
    }

    scorePassword(pass) {
        let score = 0;
        if (!pass) return score;

        // award every unique letter until 5 repetitions
        let letters = {};
        for (let i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        let variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        };

        let variationCount = 0;
        for (let check in variations) {
            variationCount += variations[check] == true ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score, 10);
    }

    handleChange = (e, obj) => {
        this.setState({strength: this.scorePassword(e.target.value)});
        if (this.props.onChange && typeof this.props.onChange === "function") {
            this.props.onChange(e, {name: this.state.name, value: e.target.value});
        }
    };

    handleGenerateClick = () => {
        let password = Helper.password(20);
        this.setState({value: password, visible: true});
        this.setState({strength: this.scorePassword(password)});
    };

    handleVisible = () => {
        this.setState({visible: !this.state.visible});
    };

    render() {
        const {...props} = this.props;
        return (
            <div>
                <Input
                    {...props}
                    fluid
                    type={this.state.visible ? "text" : "password"}
                    action={
                        this.props.showGenerator
                            ? {
                                color: "teal",
                                type: "button",
                                labelPosition: "right",
                                onClick: () => this.handleGenerateClick(),
                                icon: "key",
                                content: "Generate",
                            }
                            : null
                    }
                    icon={
                        <Icon
                            name={this.state.visible ? "eye slash" : "eye"}
                            circular
                            link
                            onClick={() => this.handleVisible()}
                        />
                    }
                    iconPosition="left"
                    actionPosition="right"
                    name={this.props.name}
                    defaultValue={this.state.value}
                    onBlur={this.props.onBlur}
                    onChange={this.handleChange}
                />
                {this.props.showStrength ? (
                    <Progress
                        percent={this.state.strength}
                        indicating
                        attached="bottom"
                        size="medium"
                    ></Progress>
                ) : null}
            </div>
        );
    }
}

export default PasswordInput;
