import React from "react";
import {Redirect, Route} from "react-router-dom";
import Session from "../helper/Session";

const PrivateRoute = (props) => {

    const authentication = props.token || props.authentication;
    if (authentication) {
        return <Route path={props.path} exact={props.exact} component={props.component} children={props.children}/>
    } else {
        Session.set('loginPath', props.path)
        return <Redirect to="/login"/>
    }

};
export default PrivateRoute;