const Helper = {
    getUUID: () => {
        let d = new Date().getTime();
        let d2 = (performance && performance.now && performance.now() * 1000) || 0;
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
            c
        ) {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
    },
    password: (passwordLength = 10) => {
        let capitals = "QWERTYUIOPASDFGHJKLZXCVBNM";
        let small = "qwertyuiopasdfghjklzxcvbnm";
        let digits = "1234567890";
        let symbols = "-_=+[]{}!@#$%^&*();:'|,<.>/?`";
        let all = capitals + small + digits + symbols;

        let getRandomSymbol = function (sourceString) {
            return sourceString[Math.floor(Math.random() * sourceString.length)];
        };

        let alphabet = all.split("");
        let passwordArray = [];
        passwordArray.push(getRandomSymbol(capitals));
        passwordArray.push(getRandomSymbol(small));
        passwordArray.push(getRandomSymbol(digits));
        passwordArray.push(getRandomSymbol(symbols));

        for (let i = 0; i < passwordLength - 4; i++) {
            passwordArray.push(getRandomSymbol(all));
        }

        let shuffle = function (a) {
            for (let i = a.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [a[i], a[j]] = [a[j], a[i]];
            }
            return a;
        };

        let password = shuffle(passwordArray).join("");

        return password;
    },
};
export default Helper;
