import React, {Component} from "react";
import DatePicker from "react-semantic-ui-datepickers";
import {Checkbox, Dropdown, Icon, Input, Label, TextArea,} from "semantic-ui-react";
import CreateList from "./CreateList";
import MultiCheckbox from "./MultiCheckbox";
import MultiSelectList from "./MultiSelectList";
import PasswordInput from "./PasswordInput";
import Util from "../helper/Util";

class FormField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            name: this.props.name,
            errors: this.props.errors || [],
        };
    }

    onChange = (e, obj) => {
        let inputValue = null;
        if (obj) {
            if (obj.value !== undefined) {
                inputValue = obj.value;
            } else if (obj.checked !== null) {
                inputValue = obj.checked;
            }
        } else {
            inputValue = e.target.value;
        }

        this.setState({value: inputValue});
        if (Util.isFunction(this.props.onChange)) {
            this.props.onChange(e, {name: this.state.name, value: inputValue, subName: this.props.subName});
        }
    };

    handleValidation = (value) => {
        if (!value) {
            value = this.state.value || "";
        }

        let errors = this.state.errors;

        let requireKey = 'require_' + this.props.name;

        if (this.props.required) {
            let requireErrorIndex = errors.findIndex(
                (error) => error.key === requireKey + this.props.name
            );
            if (requireErrorIndex > -1) {
                errors.splice(requireErrorIndex, 1);
            }
        }

        let minKey = 'min_' + this.props.name;

        if (this.props.min) {
            let minErrorIndex = errors.findIndex(
                (error) => error.key === minKey
            );
            if (minErrorIndex > -1) {
                errors.splice(minErrorIndex, 1);
            }
        }

        let maxKey = 'max_' + this.props.name;

        if (this.props.min) {
            let minErrorIndex = errors.findIndex(
                (error) => error.key === maxKey
            );
            if (minErrorIndex > -1) {
                errors.splice(minErrorIndex, 1);
            }
        }

        if (this.props.required) {
            let requireErrorIndex = errors.findIndex(
                (error) => error.key === requireKey
            );
            if (requireErrorIndex > -1) {
                errors.splice(requireErrorIndex, 1);
            }
        }

        if (this.props.required && value.length === 0) {
            errors.push({title: this.props.label, content: "This value is required.", key: requireKey});
        }
        if (this.props.max && value.length >= this.props.max) {
            errors.push({title: this.props.label, content: `Length must be smaller than ${this.props.max}`, key: maxKey});
        } else if (this.props.min && value.length <= this.props.min) {
            errors.push({title: this.props.label, content: `Length must be bigger than ${this.props.min}`, key: minKey});
        }
        if (this.props.validations) {
            this.props.validations.forEach((validation) => {
                let errorContent = validation.message || "Validation Error!";
                let validationKey = validation.key + '_' + this.state.name;
                if (!value.match(validation.mask)) {
                    if (
                        !errors.find((error) => error.key === validationKey)
                    ) {
                        errors.push({title: this.props.label, content: errorContent, key: validationKey});
                    }
                } else {
                    let errorIndex = errors.findIndex(
                        (error) => error.key === validationKey
                    );
                    if (errorIndex > -1) {
                        errors.splice(errorIndex, 1);
                    }
                }
            });
        }
        this.setState({errors: errors});
        return errors;

    }
    ;

    onBlur = (e) => {
        this.handleValidation(e.target.value);
    };

    render() {
        const {...props} = this.props;

        let input;
        switch (this.props.type) {
            case "select":
                input = (
                    <Dropdown
                        placeholder={this.props.placeholder}
                        fluid
                        search
                        value={this.state.value}
                        className={this.props.className}
                        selection
                        clearable
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        options={this.props.options}
                    />
                );
                break;
            case "multi-select":
                input = (
                    <Dropdown
                        placeholder={this.props.placeholder}
                        fluid
                        multiple
                        search
                        value={this.state.value}
                        selection
                        className={this.props.className}
                        clearable
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        options={this.props.options}
                    />
                );
                break;
            case "multi-select-list":
                input = (
                    <MultiSelectList
                        fluid
                        className={this.props.className}
                        values={this.state.values}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        options={this.props.options}
                    />
                );
                break;
            case "date":
                input = (
                    <DatePicker
                        locale="tr-TR"
                        className={this.props.className}
                        format={"YYYY-MM-DD"}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                    />
                );
                break;
            case "checkbox":
                input = (
                    <Checkbox
                        toggle
                        className={this.props.className}
                        onBlur={this.onBlur}
                        checked={this.props.checked}
                        defaultChecked={this.state.value}
                        onChange={this.onChange}
                    />
                );
                break;
            case "multi-checkbox":
                input = (
                    <MultiCheckbox
                        onChange={this.onChange}
                        className={this.props.className}
                        name={this.props.name}
                        values={this.state.values}
                        options={this.props.options}
                    />
                );
                break;
            case "textarea":
                input = (
                    <TextArea
                        placeholder={this.props.placeholder}
                        onBlur={this.onBlur}
                        className={this.props.className}
                        {...props}
                        value={this.state.value}
                        onChange={this.onChange}
                    />
                );
                break;

            case "create-list":
                input = (
                    <CreateList
                        placeholder={this.props.placeholder}
                        onBlur={this.onBlur}
                        name={this.props.name}
                        {...props}
                        onChange={this.onChange}
                        values={this.state.values}
                    />
                );
                break;

            case "password":
                input = (
                    <PasswordInput
                        onBlur={this.onBlur}
                        name={this.props.name}
                        className={this.props.className}
                        icon={this.props.icon}
                        min={this.props.min}
                        max={this.props.max}
                        placeholder={this.props.placeholder}
                        onChange={this.onChange}
                        required={this.props.required}
                        showStrength={this.props.showStrength}
                    />
                );
                break;

            case "hidden":
                input = (
                    <input
                        {...props}
                        type={"hidden"}
                        value={this.state.value}
                    />
                );
                break;

            default:
                input = (
                    <Input
                        type={this.props.type}
                        icon={<Icon name={this.props.icon || "font"} circular/>}
                        iconPosition="left"
                        min={this.props.min}
                        max={this.props.max}
                        className={this.props.className}
                        onBlur={this.onBlur}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        value={this.state.value}
                        onChange={this.onChange}
                        readOnly={this.props.readOnly}
                        style={this.props.style}
                        focus
                    />
                );
                break;
        }
        return (
            <React.Fragment>
                <span>{this.props.label}</span>
                {input}
                {this.state.errors.length > 0 ? (
                    <Label
                        basic
                        color="red"
                        pointing={this.state.errors[0].pointing || true}
                        prompt
                    >
                        <Icon name={this.state.errors[0].icon || "warning"}/>
                        {this.state.errors[0].content}
                    </Label>
                ) : null}
            </React.Fragment>
        );
    }
}

export default FormField;
