import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {Container, Dropdown, Flag, Icon, Image, Menu,} from "semantic-ui-react";
import LogoLight from "../../src/logo_light.png";
import Translate from "../component/localization/Translate";
import Session from "../helper/Session";

const languages = [
    {key: "tr", value: "tr", flag: "tr", text: "Türkçe"},
    {key: "en", value: "en", flag: "gb", text: "English"},
    {key: "de", value: "de", flag: "de", text: "Deutsch"},
];

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePath: "home",
            formDialog: {open: false, caption: null, type: null},
        };

        this.inputRef = React.createRef();
    }

    logout = () => {
        if (this.props.handleLogout) {
            this.props.handleLogout();
        }
    };

    handleItemClick = (e, {name}) => this.setState({activePath: name});
    handleLanguageClick = (value) => {
        this.props.handleLanguage(value);
    };


    render() {
        const {activePath} = this.state;
        const activeLang = languages.find(
            (lang) => lang.key === Translate.getLanguage()
        );

        return (
            <Fragment>
                <Menu fixed="top" size="tiny" inverted>
                    <Container fluid>
                        <Menu.Item as="span" header>
                            <Image
                                size="small"
                                src={LogoLight}
                                style={{marginRight: "1.5em"}}
                            />
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to="/"
                            active={activePath === ""}
                            onClick={this.handleItemClick}
                        >
                            <Icon name="home"/> {Translate.home}
                        </Menu.Item>

                        <Menu.Item
                            as={Link}
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.zetacron.iptv')}
                            active={activePath === ""}

                            download>
                            <Icon name="google play"/> {Translate.download}
                        </Menu.Item>

                        {
                            Session.get("authentication") ? <Menu.Item
                                as={Link}
                                to="/manage">
                                <Icon name="android"/> {Translate.devices}
                            </Menu.Item> : ""
                        }

                        {
                            Session.get("authentication") ? <Menu.Item
                                onClick={() => {
                                    this.logout()
                                }}>
                                <Icon name="log out"/> {Translate.logout}
                            </Menu.Item> : ""
                        }

                        <Menu.Menu position="right">
                            <Dropdown
                                item
                                trigger={
                                    <span>
                    <Flag name={activeLang.flag}/> {activeLang.text}
                  </span>
                                }
                            >
                                <Dropdown.Menu>
                                    {languages
                                        .filter((lang) => lang !== activeLang)
                                        .map((lang) => {
                                            return (
                                                <Dropdown.Item
                                                    onClick={() => this.handleLanguageClick(lang.key)}
                                                    key={lang.key}
                                                >
                                                    <Flag name={lang.flag}/> {lang.text}
                                                </Dropdown.Item>
                                            );
                                        })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Container>
                </Menu>
            </Fragment>
        );
    }
}
