import Session from "./Session";
import GlobalConfig from "../config/GlobalConfig";

const Api = {
    rest: function (url, option) {
        let authentication = Session.get('authentication');
        option.headers = option.headers ? option.headers : {};
        if (authentication) {
            option.headers['Authorization'] = 'Basic ' + btoa(authentication.username + ":" + authentication.password);
        }
        return fetch(GlobalConfig.rest.url + url, option)
            .then((res) => res.json())
            .catch(console.log);
    },

    delete: function (url, data) {
        return this.rest(url, {
            method: "delete",
            body: data ? JSON.stringify(data) : null,
            headers: {
                "Content-Type": "application/json"
            }
        });
    },

    get: function (url, object) {
        return this.rest(url, {
            method: "get",
            body: JSON.stringify(object)
        });
    },
    post: function (url, data) {
        return this.rest(url, {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        });
    },
    form: function (url, object) {
        const formData = new FormData();

        for (const name in object) {
            formData.append(name, object[name]);
        }

        return this.rest(url, {
            method: "post",
            body: formData,
            headers: {},
        });
    },
};
export default Api;
