import React, {Component} from "react";
import Dialog from "./Dialog";
import {Button, Icon} from "semantic-ui-react";
import CreateForm from "./CreateForm";
import Translate from "./localization/Translate";
import GlobalContext from "./core/GlobalContext";

class FormDialog extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            size: this.props.size || 'fullscreen',
            formConfig: this.props.formConfig,
            loading: this.props.loading || false,
            processing: this.props.processing || false,
        };
        this.form = React.createRef();
    }

    submit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    handleSubmit = (inputs, errors) => {
        if (errors.length > 0) {
            return;
        }
        if (this.props.handleSubmit) {
            this.props.handleSubmit(inputs);

        }
    };

    static getDerivedStateFromProps(props) {
        return ({open: props.open, loading: props.loading, processing: props.processing, formConfig: props.formConfig});
    }

    handleClose = () => {
        this.setState({open: false});
        if (this.props.onClose && typeof this.props.onClose === "function") {
            this.props.onClose();
        }
    };

    render() {
        const {open} = this.state;

        return (
            <Dialog

                size={this.state.size}
                open={open}
                onClose={this.handleClose}
                header={
                    <React.Fragment>
                        <Icon name={this.props.icon}/>
                        {this.props.caption}
                    </React.Fragment>
                }
                footer={
                    <React.Fragment>
                        <Button onClick={this.handleClose}>
                            <Icon name="remove"/> {Translate.cancel}
                        </Button>
                        <Button color="green" onClick={this.submit}>
                            <Icon name="checkmark"/> {Translate.save}
                        </Button>
                    </React.Fragment>
                }
            >
                <CreateForm
                    processing={this.state.processing}
                    loading={this.state.loading}
                    className={this.props.className}
                    ref={this.form}
                    handleSubmit={this.handleSubmit}
                    config={this.props.config}
                />
            </Dialog>
        );
    }
}

export default FormDialog;
