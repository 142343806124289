import React, {Component} from "react";
import {Card, Dimmer, Form, Grid, Loader, Message} from "semantic-ui-react";
import FormField from "./FormField";
import Translate from "./localization/Translate";
import GlobalContext from "./core/GlobalContext";
import Util from "../helper/Util";
import Api from "../helper/Api";

class CreateForm extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            inputs: this.getConfigValues(this.props.config),
            config: this.props.config || {groups: []},
            loading: this.props.loading || false,
            errors: this.props.errors || [],
            processing: false
        };
        this.formFields = {};
        this.state.config.groups.forEach((group) => {
            group.fields.forEach((field) => {
                this.formFields[field.name] = React.createRef();
            });
        });
    }

    componentDidUpdate(prevProps, state) {
        if (prevProps !== this.props) {
            this.setState({loading: this.props.loading, errors: this.props.errors || [], processing: this.props.processing, config: this.props.config, inputs: this.getConfigValues(this.props.config)});
        }
    }


    getConfigValues = (config) => {
        const inputs = {};
        config.groups.forEach((group) => {
            group.fields.forEach((field) => {
                inputs[field.name] = field.value;
            });
        });
        return inputs;
    };

    findInput = (name) => {
        let input = null;
        for (let index = 0; index < this.state.config.groups.length; index++) {
            const group = this.state.config.groups[index];
            let field = group.fields.find((field) => field.name === name);
            if (field) {
                input = field;
                break;
            }
        }
        return input;
    };

    handleChange = (e, obj) => {
        this.setState(() => {
            let inputs = this.state.inputs;
            inputs[obj.name] = obj.value;
            if (
                this.props.onValuesChange &&
                typeof this.props.onValuesChange === "function"
            ) {
                this.props.onValuesChange(this.state.inputs, this.state.errors);
            }

            return inputs[obj.name];
        });
    };

    handleSubmit(event, _this) {
        let errors = [];
        Object.keys(_this.state.inputs).forEach(key => {
            if (_this.formFields[key]) {
                let current = _this.formFields[key].current;
                if (current && current.handleValidation) {
                    let errorList = current.handleValidation();
                    if (errorList.length > 0) {
                        errors = errors.concat(errorList);
                    }
                }
            }
        });

        this.setState({errors: errors});

        if (errors.length === 0) {
            if (_this.props.handleSubmit) {
                _this.props.handleSubmit(_this.state.inputs, errors);
            } else {
                console.log("No Handler defined " + _this.state.inputs);
            }
        }
        event.preventDefault();
        event.stopPropagation();
    }

    static contextType = GlobalContext;

    render() {
        const {translate} = this.context;
        return (
            <React.Fragment>
                {this.state.errors.length > 0 ? (
                    <Message error>
                        <Message.Header>
                            Error
                        </Message.Header>
                        <Message.List>
                            {this.state.errors.map((message) => {
                                return (
                                    <Message.Item>
                                        <strong>{message.title}</strong> : {message.content}
                                    </Message.Item>
                                );
                            })}
                        </Message.List>
                    </Message>
                ) : null}
                <form
                    className="ui form"
                    onSubmit={(e) => this.handleSubmit(e, this)}
                    ref={this.props.forwardedRef}
                >

                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted content={Translate.loading}/>
                    </Dimmer>
                    <Dimmer active={this.state.processing} inverted>
                        <Loader inverted content={Translate.processing}/>
                    </Dimmer>

                    <Grid className={this.props.className} centered={this.props.centered}>
                        <Grid.Row columns={this.state.config.columns || 3}>
                            {this.state.config.groups.map((group) => {
                                return (
                                    <Grid.Column key={group.key} width={group.columnWidth || 16}>
                                        <Card fluid color={"blue"} style={group.style}>
                                            <Card.Content class={"red"}>
                                                <Card.Header>{group.title}</Card.Header>
                                                <Card.Meta>{group.description}</Card.Meta>
                                            </Card.Content>
                                            <Card.Content>

                                                {group.fields.map((field) => {
                                                    if (this.state.loading) {
                                                        return null;
                                                    }
                                                    let dataConfig = field.dataConfig;
                                                    if (dataConfig && Util.isNullOrUndefined(field.options)) {
                                                        let valueField = dataConfig.valueExpression ? dataConfig.valueExpression : 'id'
                                                        let displayField = dataConfig.displayExpression ? dataConfig.displayExpression : 'name'
                                                        Api.post(dataConfig.url, dataConfig.filterConfig || {}).then((response) => {
                                                            field.options = [];
                                                            response.data.results.forEach((entity) => {
                                                                field.options.push({key: entity[valueField], text: entity[displayField], value: entity[valueField]});
                                                            });
                                                        });
                                                    }
                                                    return (
                                                        field.type !== 'hidden' ?
                                                            <Form.Field>
                                                                <FormField
                                                                    validations={field.validations}
                                                                    key={field.name}
                                                                    ref={
                                                                        this.formFields[field.name]
                                                                    }
                                                                    type={field.type || "text"}
                                                                    options={field.options}
                                                                    name={field.name}
                                                                    value={field.value}
                                                                    values={field.values}
                                                                    min={field.min}
                                                                    max={field.max}
                                                                    label={field.label}
                                                                    subName={field.subName}
                                                                    onChange={this.handleChange}
                                                                    icon={field.icon ? field.icon.name : null}
                                                                    iconPosition={
                                                                        field.icon ? field.icon.position : null
                                                                    }
                                                                    placeholder={field.placeholder}
                                                                    required={field.required}
                                                                />
                                                            </Form.Field>
                                                            :
                                                            <FormField type="hidden" value={field.value}
                                                                       name={field.name}/>

                                                    );
                                                })}
                                            </Card.Content>
                                            <Card.Content>
                                                {this.props.footer ? (
                                                    <div className="left">{this.props.footer}</div>
                                                ) : null}
                                                {this.props.submitButton}
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                );
                            })}
                        </Grid.Row>
                    </Grid>
                </form>
            </React.Fragment>
        );
    }
}

export default React.forwardRef((props, ref) => {
    return <CreateForm {...props} forwardedRef={ref}/>;
});
