const Session = {
    set: function (key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    },
    get: function (key) {
        let item = sessionStorage.getItem(key);
        if (item === undefined || item === null) {
            return null;
        }
        return JSON.parse(item);
    },
    setToken: function (token) {
        return this.set("token", token);
    },
    getToken: function () {
        return this.get("token");
    }
};
export default Session;
