import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Container, Grid} from "semantic-ui-react";
import Footer from "./module/Footer";
import Navbar from "./module/Navbar";
import Index from "./page/Index";
import Manage from "./page/Manage";
import Translate from "./component/localization/Translate";
import Session from "./helper/Session";
import Login from "./page/Login";
import PrivateRoute from "./component/PrivateRoute";
import GlobalContext from "./component/core/GlobalContext";

export default class App extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            activePath: "home",
            formDialog: {open: false, caption: null, type: null},
            authentication: Session.get("authentication")
        };

        this.inputRef = React.createRef();
    }

    handleLogout = () => {
        Session.set("authentication", null);
        this.setState({authentication: null})
    }

    handleLogin = (authentication) => {
        Session.set("authentication", authentication);
        this.setState({authentication: authentication});
        let loginPath = Session.get('loginPath');
        window.location.href = loginPath ? loginPath : '/';
    }

    handleLanguage = (lang) => {
        Translate.setLanguage(lang);
        this.setState({});
    };


    render() {

        return (
            <BrowserRouter>
                <div className="App" style={{height: "100%", padding: 0, margin: 0}}>
                    <Container fluid style={{height: "100%"}}>
                        <Grid
                            columns={1}
                            style={{height: "100%", padding: 0, margin: 0}}
                            divided
                        >
                            <Grid.Row
                                stretched
                                style={{height: "100%", padding: 0, margin: 0, paddingTop: 60}}
                            >
                                <Grid.Column
                                    style={{height: "100%", padding: 0, margin: 0, paddingTop: 0}}
                                >
                                    <Navbar authentication={this.state.authentication}
                                            handleLanguage={this.handleLanguage} handleLogout={this.handleLogout}/>

                                    <Container fluid

                                               style={{
                                                   height: "100%",
                                                   padding: 10,
                                                   margin: 0,
                                                   paddingTop: 10,
                                               }}
                                    >
                                        <Switch>
                                            <Route exact path="/">
                                                <Container>
                                                    <Index/>
                                                </Container>
                                            </Route>
                                            <PrivateRoute authentication={this.state.authentication} exact
                                                          path="/manage">
                                                <Manage/>
                                            </PrivateRoute>
                                            <Route exact path="/login">
                                                <Login handleLogin={this.handleLogin}/>
                                            </Route>
                                        </Switch>
                                    </Container>
                                    <Footer/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </div>
            </BrowserRouter>
        );
    }
};
