import React, {Component} from "react";
import {ReactSortable} from "react-sortablejs";
import {Dimmer, Grid, Loader, Table, TableBody} from "semantic-ui-react";
import Generator from "../helper/Generator";
import FormField from "./FormField";
import Translate from "./localization/Translate";

const TBodySource = React.forwardRef((props, ref) => {
    return <TableBody ref={ref}> <Dimmer active={this.state.loading} inverted>
        <Loader inverted content={Translate.loading}/>
    </Dimmer>{props.children}</TableBody>;
});
const TBodyTarget = React.forwardRef((props, ref) => {
    return <TableBody ref={ref}> <Dimmer active={this.state.loading} inverted>
        <Loader inverted content={Translate.loading}/>
    </Dimmer>{props.children}</TableBody>;
});

function getDifferences(sources, targets) {
    const differences = [];
    sources.map(source => {
        let foundObjects = targets.filter(target => target.value === source.value);
        if (foundObjects.length === 0) {
            differences.push(source);
        }
    });
    return differences;
}

class MultiSelectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options || [],
            values: this.props.values || [],
            permanentOptions: this.props.options || [],
            canSortable: true
        };
    }

    handleOptionFilter = (e) => {
        const options = this.state.permanentOptions.filter((option) =>
            option.text.toLowerCase().includes(e.target.value)
        );
        this.setState({
            options: options.filter(
                (option) =>
                    !this.state.values.find((value) => value.text === option.text)
            ),
        });
    };

    handleValueFilter = (e) => {

        const values = getDifferences(this.state.permanentOptions, this.state.options).filter((value) =>
            value.text.toLowerCase().includes(e.target.value)
        );
        this.setState({
            values: values.filter(
                (value) =>
                    !this.state.options.find((option) => option.text === value.text)
            ),
            canSortable: e.target.value.length === 0
        });
    };

    handleNewOptions = (newOptions) => {
        if (JSON.stringify(this.state.options) === JSON.stringify(newOptions)) {
            return;
        }
        this.setState({options: newOptions});
    };


    handleNewValues = (newValues) => {
        if (JSON.stringify(this.state.values) === JSON.stringify(newValues)) {
            return;
        }
        this.setState({values: newValues});
        setTimeout(() => {
            if (this.props.onChange && typeof this.props.onChange === "function") {
                this.props.onChange(null, {
                    name: this.props.name,
                    value: getDifferences(this.state.permanentOptions, this.state.options).map((item) => item.value),
                });
            }
        });
    };

    render() {
        const group = Generator.getUUID();
        return (
            <Grid stackable>
                <Grid.Row columns={2}>
                    <Grid.Column style={{height: "100%"}}>
                        <Table size="small" className="multi-select-list" fluid>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <FormField
                                            type="text"
                                            name="optionFilter"
                                            onChange={this.handleOptionFilter}
                                            fluid
                                            placeholder="Filter"
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <ReactSortable
                                multiDrag
                                sort={false}
                                multiDragKey="ctrl"
                                tag={TBodySource}
                                selectedClass="chosen-item"
                                ghostClass="ghost-item"
                                group={this.props.group || group}
                                animation={200}
                                delayOnTouchStart={true}
                                list={this.state.options}
                                setList={this.handleNewOptions}
                                delay={2}
                            >
                                {this.state.options.map((option) => {
                                    return (
                                        <Table.Row key={"option" + option.key} value={option.value}>
                                            <Table.Cell>{option.text}</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </ReactSortable>
                        </Table>
                    </Grid.Column>
                    <Grid.Column style={{height: "100%"}}>
                        <Table size="small" className="multi-select-list" fluid>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <FormField
                                            type="text"
                                            name="valueFilter"
                                            onChange={this.handleValueFilter}
                                            fluid
                                            placeholder="Filter"
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <ReactSortable
                                multiDrag
                                multiDragKey="ctrl"
                                tag={TBodyTarget}
                                selectedClass="chosen-item"
                                ghostClass="ghost-item"
                                group={this.props.group || group}
                                animation={200}
                                sort={this.state.canSortable}
                                delayOnTouchStart={true}
                                list={this.state.values}
                                setList={this.handleNewValues}
                                delay={2}
                            >
                                {this.state.values.map((value) => {
                                    return (
                                        <Table.Row key={"value-" + value.key} value={value.value}>
                                            <Table.Cell>{value.text}</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </ReactSortable>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default MultiSelectList;
