import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    Button,
    Checkbox,
    Container,
    Dimmer,
    Dropdown,
    Icon,
    Loader,
    Pagination,
    Select,
    Statistic,
    Table
} from "semantic-ui-react";
import FormField from "./FormField";
import FormDialog from "../component/FormDialog";
import Api from "../helper/Api";
import ConfirmDialog from "./ConfirmDialog";
import Session from "../helper/Session";
import ContextMenu from "./ContextMenu";
import Util from "../helper/Util";
import GlobalContext from "./core/GlobalContext";

let filterTimeOut = null;

class DataTable extends Component {
    static contextType = GlobalContext;

    constructor(props, context) {
        super(props, context);
        this.scrollPosition = {x: 0, y: 0};
        this.autoScroll = true;
        this.state = {
            activePage: 1,
            rows: [],
            loading: true,
            formLoading: false,
            formProcessing: false,
            formDialog: false,
            autoScroll: true,
            totalPages: 1,
            totalRows: 0,
            endlessScrolling: this.props.endlessScrolling || false,
            rowActions: [],
            tableConfig: this.props.tableConfig || {},
            scrollPosition: this.scrollPosition,
            columns: this.props.tableConfig.columns,
            filterConfig: this.props.tableConfig.filterConfig || {},
            rowTemplate: this.props.rowTemplate || {},
            formConfig: this.props.formConfig || {}
        };

        this.root = React.createRef();
        this.init(this.props);
    }

    getCondition = (condition, ...params) => {
        let _condition = condition;
        if (Util.isFunction(condition)) {
            _condition = condition(...params);
        } else if (Util.isNullOrUndefined(condition)) {
            _condition = true;
        }
        return _condition;
    }

    basicTableActions = () => {
        return [
            {
                key: "groupNew",
                position: 'left',
                buttons: [
                    {
                        key: "new",
                        icon: "plus",
                        color: "green",
                        text: () => {
                            return this.context.translate.new;
                        },
                        handler: () => {
                            let formConfig = this.state.formConfig;
                            formConfig.groups.forEach((group) => {
                                group.fields.forEach(field => {
                                    field.value = null;
                                });
                            });
                            this.setState({formDialog: true, formForNew: true, formConfig: formConfig});
                        },
                        condition: () => {
                            return true;
                        }
                    }
                ]
            }, {
                key: "groupDelete",
                position: 'left',
                buttons: [
                    {
                        key: "delete",
                        icon: "remove",
                        color: "red",
                        text: "Delete",
                        options: [
                            {
                                key: "deleteSelected",
                                text: `Delete Selected (${this.state.rows.filter(row => row.checked).length})`,
                                handler: () => {
                                    this.setState({deleteMultiConfirmDialog: true});
                                },
                                condition: this.state.rows.filter(row => row.checked).length > 0
                            }, {
                                key: "deleteAll",
                                text: "Delete All",
                                handler: () => {
                                    this.setState({deleteAllConfirmDialog: true});
                                },
                                condition: this.state.rows.length > 0
                            }

                        ],
                        condition: () => {
                            return true;
                        }
                    }
                ]
            }

        ]
    };

    basicRowActions = () => {
        return [
            {
                key: "edit",
                icon: "edit",
                text: this.context.translate.edit,
                handler: (row) => {
                    this.loadEntity(row.id.value);
                },
                condition: (row) => {
                    return row && (row.editable === undefined || row.editable.value);
                }
            },
            {
                key: "delete",
                icon: "delete",
                text: this.context.translate.delete,
                handler: (row) => {
                    this.setState({deleteConfirmDialog: true, deleteId: row.id.value});
                },
                condition: (row) => {
                    return row && (row.editable === undefined || row.editable.value);
                }
            }
        ]
    };

    loadEntity = (id, formConfig) => {
        formConfig = formConfig || this.state.formConfig;
        this.setState({formDialog: true, formLoading: true});
        Api.get(formConfig.getUrl + '/' + id).then((response) => {
            if (!response.data) {
                return;
            }
            let entity = response.data;
            let hiddenFields = [];

            Object.keys(entity).forEach((key) => {
                formConfig.groups.forEach((group) => {
                    let field = group.fields.find((field) => {
                        return field.name === key;
                    });
                    if (field) {
                        field.value = entity[key];
                    } else {
                        if (hiddenFields.indexOf(key) === -1) {
                            hiddenFields.push(key);
                        }
                    }
                });

            });
            hiddenFields.forEach((key) => {
                formConfig.groups[0].fields.push({
                    name: key,
                    type: "hidden",
                    value: entity[key]
                })
            });
            this.setState({formConfig: formConfig, formLoading: false})
        });
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {rows, totalRows} = this.state;
        let actions = this.generateActions();
        if (prevProps.tableActions !== this.props.tableActions && prevProps.rowActions !== this.props.rowActions) {
            this.setState({
                tableActions: actions.tableActions,
                rowActions: actions.rowActions
            });
        } else if (prevProps.tableActions !== this.props.tableActions) {
            this.setState({
                tableActions: actions.tableActions
            });
        } else if (prevProps.rowActions !== this.props.rowActions) {
            this.setState({
                rowActions: actions.rowActions
            });
        }

        let updatedRows = this.props.updatedRows;
        if (!Util.areObjectsEqual(updatedRows, prevProps.updatedRows)) {
            updatedRows.forEach((updatedRow) => {
                let rowIndex = rows.findIndex(row => row.id.value === updatedRow.id.value)
                if (rowIndex > -1) {
                    rows[rowIndex] = updatedRow;
                }
            });
            this.sendRowsUpdate(rows);
            this.setState({rows: rows});
        }

        let deletedRows = this.props.deletedRows;
        if (deletedRows !== prevProps.deletedRows) {
            deletedRows.forEach((deletedRow) => {
                deletedRow = (Util.isObject(deletedRow) ? deletedRow.id.value : deletedRow)
                let rowIndex = rows.findIndex(row => row.id.value === deletedRow)
                if (rowIndex > -1) {
                    rows.splice(rowIndex, 1);
                }
            });
            this.sendRowsUpdate(rows);
            this.setState({rows: rows, totalRows: totalRows - 1});
        }

    }

    handleSubmit = (inputs) => {
        this.setState({formProcessing: true});
        Api.post(this.state.formConfig.postUrl, inputs).then(response => {

            let rowIndex = this.state.rows.findIndex((row) => {
                return row.id.value === inputs.id;
            });

            let rows = this.state.rows;
            let totalRows = this.state.totalRows;
            if (rowIndex > -1) {
                rows[rowIndex] = this.props.rowTemplate(inputs);

            } else {
                rows.unshift(this.props.rowTemplate(inputs));
                totalRows++;
            }
            rows = this.getDefaultTableStructure(rows).rows;
            this.setState({formDialog: false, formProcessing: false, formForNew: false, rows: rows, totalRows: totalRows});
        });
    };

    handleDeleteConfirm = () => {
        Api.delete(this.state.formConfig.deleteUrl + '/' + this.state.deleteId);
        let rows = this.state.rows;
        let rowIndex = this.state.rows.findIndex(row => {
            return row.id.value === this.state.deleteId;
        });
        let totalRows = this.state.totalRows;
        rows.splice(rowIndex, 1);
        this.setState({deleteConfirmDialog: false, rows: rows, deleteId: null, totalRows: totalRows - 1});
    };

    handleAction = (action, row) => {
        action.handler(row);
    };

    sendRowsUpdate = (rows) => {
        let handleRowsUpdate = this.props.handleRowsUpdate;
        if (Util.isFunction(handleRowsUpdate)) {
            handleRowsUpdate(rows);
        }
    };

    generateActions = () => {
        return {
            tableActions: this.basicTableActions(this.state, this.context).concat(this.props.tableActions ? this.props.tableActions : []),
            rowActions: this.basicRowActions(this.state, this.context).concat(this.props.rowActions ? this.props.rowActions : [])
        }
    }

    getDefaultTableStructure = (rows) => {

        let checkColumn = {
            caption: <Checkbox value={rows} onClick={(e, {checked, value}) => {
                value.forEach(row => {
                    row.checked = checked;
                });
                let actions = this.generateActions();
                this.setState({rows: value, tableActions: actions.tableActions, rowActions: actions.rowActions}, () => {
                    this.sendRowsUpdate(value);
                });
            }
            }/>,
            name: "checkColumn",
            width: "30px",
        };

        let actionColumn = {
            caption: "#",
            name: "actionColumn",
            width: "60px",
        };
        let columns = this.state.columns;
        let actionColumnIndex = columns.findIndex((column) => column.name === actionColumn.name);
        if (actionColumnIndex < 0) {
            columns.push(actionColumn)
        }

        let checkColumnIndex = columns.findIndex((column) => column.name === checkColumn.name);
        if (checkColumnIndex < 0) {
            columns.unshift(checkColumn)
        } else {
            columns[checkColumnIndex] = checkColumn;
        }

        rows.forEach((row) => {
            row["checkColumn"] = {
                text: () => {
                    return <Checkbox key={'check_' + row.id} checked={row.checked}
                                     onChange={
                                         (e, {checked}) => {
                                             setTimeout(() => {
                                                 let rows = [...this.state.rows];
                                                 let rowIndex = rows.findIndex((_row) => _row.id.value === row.id.value);
                                                 rows[rowIndex].checked = checked;
                                                 this.sendRowsUpdate(rows);
                                                 const actions = this.generateActions();
                                                 this.setState({
                                                     rows,
                                                     tableActions: actions.tableActions
                                                 });
                                             });
                                         }
                                     }
                    />
                }
            };
            row["actionColumn"] = {
                text: () => {
                    return (
                        <div>

                            <Dropdown
                                icon='ellipsis horizontal'
                                floating
                                direction={"left"}
                                button
                                compact
                                className='icon'
                            >
                                <Dropdown.Menu>
                                    {this.state.rowActions.filter(action => this.getCondition(action.condition, row)).map((action) => {
                                        return (

                                            <React.Fragment>
                                                <Dropdown.Item
                                                    onClick={() => this.handleAction(action, row)}
                                                    icon={action.icon}
                                                    key={action.key}
                                                    text={Util.isFunction(action.text) ? action.text(row) : action.text}
                                                />
                                            </React.Fragment>

                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )
                },
            };
        });
        return {columns: columns, rows: rows};
    };

    init = () => {
        let filterConfig = this.state.filterConfig;
        filterConfig.size = Session.get('pageSize') || this.state.filterConfig.size;
        this.loadRows();
    };

    handlePaginationChange = (e, {activePage}) => {
        let filterConfig = this.state.filterConfig;
        filterConfig.page = activePage;
        if (Util.isFunction(this.props.handleFilterConfig)) {
            this.props.handleFilterConfig(filterConfig);
        }
        this.setState({activePage: activePage, filterConfig: filterConfig}, () => {
            this.loadRows();
        });
    };

    getFilters = () => {
        const filters = [];
        this.state.columns.forEach((column) => {
            if (column.filterConfig && column.filterConfig.value) {
                filters.push({
                    name: column.name,
                    operator: column.filterConfig.operator,
                    value: column.filterConfig.value
                });
            }
        });
        return filters;
    };

    handleScroll = (event) => {
        const {activePage, totalPages, endlessScrolling} = this.state;
        if (!endlessScrolling) {
            return;
        }
        let target = event.target;
        this.scrollPosition.y = target.scrollTop;
        if (!this.state.loading && (this.scrollPosition.y + target.offsetHeight) === target.scrollHeight) {
            if (activePage < totalPages) {
                let filterConfig = this.state.filterConfig;
                filterConfig.page = filterConfig.page + 1;
                this.setState({activePage: filterConfig.page, loading: true});
                this.loadRows(false);
            }

        }
    }

    handleFilter = (obj, column) => {
        if (filterTimeOut) {
            clearTimeout(filterTimeOut);
        }
        filterTimeOut = setTimeout(() => {
            column.filterConfig.value = obj.value;
            let filterConfig = this.state.filterConfig;
            filterConfig.filters = this.getFilters();
            filterConfig.page = 1;
            this.setState({filterConfig: filterConfig, activePage: filterConfig.page});
            this.loadRows()
        }, 500);
    };

    handleRowSize = (size) => {
        let filterConfig = this.state.filterConfig;
        filterConfig.size = size;
        this.setState({filterConfig: filterConfig}, () => {
            this.loadRows();
        });
        Session.set('pageSize', size);
    };

    handleSort = (column) => {
        this.state.columns.forEach(($column) => {
            if ($column !== column) {
                $column.sortBy = null;
                $column.sortIcon = "sort";
            }
        });

        if (Util.isNullOrUndefined(column.sortBy)) {
            column.sortBy = "asc";
            column.sortIcon = "sort up";
        } else if (column.sortBy === "asc") {
            column.sortBy = "desc";
            column.sortIcon = "sort down";
        } else {
            column.sortBy = "asc";
            column.sortIcon = "sort up";
        }
        let filterConfig = this.state.filterConfig;
        filterConfig.sort = column.name;
        filterConfig.order = column.sortBy;

        this.setState({sortField: column.name, filterConfig: filterConfig}, () => {
            this.loadRows();
        });
    };

    loadRows = (reload) => {
        let needReload = Util.isNullOrUndefined(reload) || reload;

        this.setState({loading: needReload, formProcessing: true});
        const {tableConfig, filterConfig, rowTemplate, endlessScrolling} = this.state;
        return Api.post(tableConfig.apiURL + '/list', filterConfig).then((response) => {
            if (!response.data) {
                this.setState({rows: [], totalPages: 1});
            } else {
                let rows = needReload ? [] : [...this.state.rows];
                response.data.results.forEach(row => {
                    rows.push(rowTemplate(row));
                });
                let generatedRows = this.getDefaultTableStructure(rows).rows;
                let actions = this.generateActions();
                this.setState({
                    rows: generatedRows,
                    totalPages: response.data.totalPages,
                    totalRows: response.data.totalRows,
                    showedTotalRows: endlessScrolling ? rows.length : null,
                    loading: false,
                    formProcessing: false,
                    tableActions: actions.tableActions,
                    rowActions: actions.rowActions
                });
                if (needReload) {
                    this.sendRowsUpdate(generatedRows);
                }
            }
        });
    }

    render() {

        const {translate} = this.context;
        const {
            activePage,
            boundaryRange,
            totalRows,
            totalPages,
            loading,
            tableActions,
            rowActions,
            columns,
            filterConfig,
            rows,
            endlessScrolling
        } = this.state;

        const tableAction = (action) => {
            {
                if (this.getCondition(action.condition)) {
                    return (
                        action.options ?
                            <Dropdown
                                text={action.text}
                                icon={action.icon}
                                key={action.key}
                                floating
                                compact
                                labeled
                                disabled={Util.isFunction(action.disabled) ? action.disabled() : action.disabled}
                                color={action.color}
                                button
                                className='icon'
                            >
                                <Dropdown.Menu>
                                    {action.options.map(subAction => {
                                        {
                                            if (this.getCondition(subAction.condition)) {
                                                return (
                                                    <Dropdown.Item
                                                        disabled={Util.isFunction(subAction.disabled) ? subAction.disabled() : subAction.disabled}
                                                        onClick={subAction.handler}>{Util.isFunction(subAction.text) ? subAction.text() : subAction.text}</Dropdown.Item>
                                                )
                                            }
                                        }

                                    })}

                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            <Button labelPosition='left' icon={action.icon}
                                    key={action.key}
                                    content={Util.isFunction(action.text) ? action.text() : action.text} compact
                                    color={action.color}
                                    onClick={action.handler}/>
                    )
                }
            }


        }

        return (
            <div ref={this.root}>
                <Container fluid>
                    {tableActions ? tableActions.map((group) => {
                        return (
                            <React.Fragment>
                                <Button.Group key={group.key}>
                                    {group.buttons.map(button => {
                                        return tableAction(button)
                                    })}
                                </Button.Group>
                                {" "}
                            </React.Fragment>
                        )
                    }) : null}
                    <Statistic.Group size={'mini'} style={{float: 'right'}}>
                        <Statistic horizontal color={"green"}>
                            <Statistic.Value>
                                {endlessScrolling ?
                                    <>
                                        {this.state.showedTotalRows} / {" "}
                                    </>
                                    : null}
                                {totalRows}</Statistic.Value>
                            <Statistic.Label>Total</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>
                </Container>
                <Table size="small" className="datatable" striped>
                    <Table.Header>
                        <Table.Row>
                            {columns.map((column) => {
                                let filterConfig = column.filterConfig;
                                let isFilterColumn = column.filterable && filterConfig;

                                if (isFilterColumn) {
                                    if (filterConfig.input.dataConfig && Util.isNullOrUndefined(filterConfig.input.options)) {
                                        let dataConfig = filterConfig.input.dataConfig;
                                        let valueField = dataConfig.valueExpression ? dataConfig.valueExpression : 'id'
                                        let displayField = dataConfig.displayExpression ? dataConfig.displayExpression : 'name'
                                        Api.post(dataConfig.url, dataConfig.filterConfig || {}).then((response) => {
                                            filterConfig.input.options = [];
                                            response.data.results.forEach((entity) => {
                                                filterConfig.input.options.push({key: entity[valueField], text: entity[displayField], value: entity[valueField]});
                                            });
                                        });
                                    }
                                }

                                return isFilterColumn ? (
                                    <Table.HeaderCell
                                        style={{
                                            padding: 5,
                                            width: column.width ? column.width : "auto",
                                        }}
                                        key={"th-filter-" + column.name}
                                    >
                                        <FormField
                                            type={filterConfig.input.type}
                                            className="filter-input"
                                            placeholder={column.caption}
                                            options={filterConfig.input.options}
                                            onChange={(e, obj) => this.handleFilter(obj, column)}
                                            icon="search"
                                            iconPosition="left"
                                        />
                                    </Table.HeaderCell>
                                ) : (
                                    <Table.HeaderCell
                                        key={"th-filter-" + column.name}
                                        style={{width: column.width ? column.width : "auto"}}
                                    />
                                );
                            })}
                        </Table.Row>
                        <Table.Row>
                            {columns.map((column) => {
                                return (
                                    <Table.HeaderCell
                                        key={"th-" + column.name}
                                        style={{width: column.width ? column.width : "auto"}}
                                    >
                                        {column.sortable ? (
                                            <Link onClick={() => this.handleSort(column)}>
                                                <Icon
                                                    name={column.sortIcon ? column.sortIcon : "sort"}
                                                />
                                                {column.caption}
                                            </Link>
                                        ) : (
                                            column.caption
                                        )}
                                    </Table.HeaderCell>
                                );
                            })}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body key={'row'} onScroll={this.handleScroll}>

                        {
                            loading ?
                                <Table.Row><Table.Cell colSpan={columns.length}><Dimmer active={loading} inverted>
                                    <Loader inverted content={translate.loading}/>
                                </Dimmer></Table.Cell></Table.Row> :
                                rows.map((row, index) => {
                                    row.$id = `row-${row["id"].value}`;
                                    return (

                                        <Table.Row key={'row-' + row["id"].value} id={row.$id}
                                                   className={`${row.checked ? 'selected' : ''}`}
                                                   style={{verticalAlign: "top", height: "fit-content"}}>
                                            {columns.map((column) => {
                                                let text = row[column.name]?.text;
                                                text = Util.isFunction(text) ? text() : text;
                                                if (Util.isObject(text)) {
                                                    text = React.isValidElement(text) ? text : JSON.stringify(text);
                                                }
                                                return (
                                                    <Table.Cell
                                                        key={column.name + row["id"].value}
                                                        style={{width: column.width ? column.width : "auto"}}
                                                    >
                                                        {text}
                                                    </Table.Cell>
                                                );
                                            })}
                                        </Table.Row>

                                    )
                                })

                        }

                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={columns.length}>
                                {totalPages > 1 ?
                                    <React.Fragment>
                                        <label style={{float: 'left'}}>Page Size:
                                            {" "}
                                            <Select
                                                defaultValue={filterConfig.size + ''} options={[
                                                {key: '10', value: '10', text: '10'},
                                                {key: '25', value: '25', text: '25'},
                                                {key: '50', value: '50', text: '50'},
                                                {key: '75', value: '75', text: '75'},
                                                {key: '100', value: '100', text: '100'},
                                                {key: '150', value: '150', text: '150'},
                                                {key: '200', value: '200', text: '200'},
                                                {key: '250', value: '250', text: '250'}
                                            ]} onChange={(e, selectProps) => {
                                                this.handleRowSize(selectProps.value);
                                            }}/>
                                        </label>

                                        {!endlessScrolling ? <Pagination
                                            floated="right"
                                            activePage={activePage}
                                            ellipsisItem={{
                                                content: <Icon name="ellipsis horizontal"/>,
                                                icon: true,
                                            }}
                                            size="mini"
                                            boundaryRange={boundaryRange}
                                            onPageChange={this.handlePaginationChange}
                                            firstItem={{
                                                content: <Icon name="angle double left"/>,
                                                icon: true,
                                            }}
                                            lastItem={{
                                                content: <Icon name="angle double right"/>,
                                                icon: true,
                                            }}
                                            prevItem={{content: <Icon name="angle left"/>, icon: true}}
                                            nextItem={{
                                                content: <Icon name="angle right"/>,
                                                icon: true,
                                            }}
                                            totalPages={totalPages}
                                        /> : null}

                                    </React.Fragment>
                                    : null
                                }

                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <ConfirmDialog title={translate.delete} handleConfirm={this.handleDeleteConfirm}
                               open={this.state.deleteConfirmDialog}
                               handleClose={() => this.setState({deleteConfirmDialog: false})}>
                    {translate.deleteConfirm}
                </ConfirmDialog>
                <ConfirmDialog title={translate.delete}
                               handleConfirm={() => {
                                   let {rows, totalRows} = this.state;
                                   const ids = rows.filter(row => row.checked).map(row => row.id.value);
                                   Api.delete(this.state.tableConfig.apiURL + '/delete-multi', ids).then((response) => {
                                       if (response.success) {
                                           this.setState({
                                               rows: rows.filter(row => (Util.isNullOrUndefined(row.checked) || !row.checked)),
                                               deleteMultiConfirmDialog: false,
                                               totalRows: totalRows - rows.filter(row => row.checked).length
                                           });
                                       }
                                   });
                               }}
                               open={this.state.deleteMultiConfirmDialog}
                               handleClose={() => this.setState({deleteMultiConfirmDialog: false})}>
                    {translate.deleteMultiConfirm}
                </ConfirmDialog>
                <ConfirmDialog title={translate.delete}
                               handleConfirm={() => {
                                   Api.delete(this.state.tableConfig.apiURL + '/delete-all').then((response) => {
                                       if (response.success) {
                                           let actions = this.generateActions();
                                           this.setState({
                                               rows: [],
                                               deleteAllConfirmDialog: false,
                                               tableActions: actions.tableActions
                                           });
                                       }
                                   });
                               }}
                               open={this.state.deleteAllConfirmDialog}
                               handleClose={() => this.setState({deleteAllConfirmDialog: false})}>
                    {translate.deleteAllConfirm}
                </ConfirmDialog>
                <FormDialog caption={this.state.formForNew ? translate.new : translate.edit}
                            icon={this.state.formForNew ? 'plus square outline' : 'edit outline'}
                            loading={this.state.formLoading}
                            processing={this.state.formProcessing} className={"datatable-form-dialog"}
                            config={this.state.formConfig}
                            size={"small"} open={this.state.formDialog}
                            onClose={() => this.setState({formDialog: false, formForNew: false})}
                            handleSubmit={this.handleSubmit}/>

                <ContextMenu targets={this.state.rows} targetSelector={'$id'} options={rowActions}/>

            </div>
        );
    }
}

export default DataTable;
