import React, {Component} from "react";
import {Dropdown, Icon, Menu} from "semantic-ui-react";
import Generator from "../helper/Generator";

class ContextMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            clientY: 0,
            clientX: 0,
            activeTarget: {},
            options: this.props.options || [],
            targetSelector: this.props.targetSelector,
            id: Generator.getUUID(),
            x: 0,
            y: 0,
            targets: this.props.targets || []
        };
        this.handleClickOutside = (event) => {
            if (event.which === 1) {
                if (event.target.closest('.context-menu')) {
                    return;
                }
                this.setState({show: false});
            }
        };

        this.handleDocumentContextMenu = (event) => {
            event.preventDefault();

            document.querySelectorAll('.context-menu').forEach((contextMenu) => {
                if (contextMenu.getAttribute('id') !== this.state.id) {
                    contextMenu.style.display = 'none';
                }
            });
            let activeTarget;
            for (const target of this.state.targets) {
                if (event.target.closest('#' + target[this.state.targetSelector])) {
                    activeTarget = target;
                    break;
                }
            }

            if (!activeTarget) {
                this.setState({show: false});
                return;
            }

            this.setState({activeTarget: activeTarget, show: true, x: (event.pageX - 10) + 'px', y: (event.pageY - 60) + 'px'})

        }

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
        document.addEventListener('contextmenu', this.handleDocumentContextMenu, false);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
        document.removeEventListener('contextmenu', this.handleDocumentContextMenu, false);

    }

    static getDerivedStateFromProps(props) {
        return ({targets: props.targets, options: props.options});
    }

    handleMenuClick = (handler) => {
        if (handler && typeof handler === "function") {
            this.setState({show: false});
            handler(this.state.activeTarget);
        }
    }

    render() {
        const {activeTarget, show, x, y} = this.state;
        if (!activeTarget) {
            return null;
        }
        return (

            <Menu vertical id={this.state.id}
                  className={'context-menu'}
                  style={{
                      position: 'absolute',
                      zIndex: 9999999999999999,
                      display: show ? 'unset' : 'none',
                      left: x,
                      top: y,
                      margin: 0,
                      boxShadow: '0px 0px 20px -1px rgba(0,0,0,0.3)'
                  }}>

                {this.state.options.map(option => {
                    return (
                        <>
                                <Menu.Item onClick={() => this.handleMenuClick(option.handler)}
                                           style={{paddingLeft: '5px'}}> {option.icon ? <>
                                <Icon style={{float: 'left', marginRight: "5px"}}
                                      name={option.icon}/> {" "} </> : null}
                                {typeof option.text === "function" ? option.text() : option.text}
                            </Menu.Item>
                            {
                                option.options && option.options.length > 0 ?
                                    <Dropdown text={typeof option.text === "function" ? option.text() : option.text}
                                              pointing='left' className='link item'>
                                        <Dropdown.Menu>
                                            {option.options.map(subOption => {
                                                return (

                                                    <Dropdown.Item style={{paddingLeft: '5px'}}
                                                                   onClick={() => this.handleMenuClick(subOption.handler)}>
                                                        {option.icon ? <> <Icon style={{float: 'left', marginRight: "5px"}}
                                                                                name={option.icon}/> {" "} </> : null}
                                                        {typeof subOption.text === "function" ? subOption.text() : subOption.text}</Dropdown.Item>
                                                )

                                            })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    : null

                            }
                        </>
                    )
                })
                }

            </Menu>

        );
    }
}

export default ContextMenu;
