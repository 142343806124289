const Util = {
    isNullOrUndefined: function (obj) {
        return (obj === undefined || obj === null);
    },
    isFunction: function (obj) {
        return typeof obj === "function";
    },
    isObject: function (obj) {
        return typeof obj === "object";
    },
    isString: function (obj) {
        return typeof obj === "string";
    },
    areObjectsEqual: function (obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
};
export default Util;
