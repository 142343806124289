import React, {Component} from "react";
import {Container, Segment} from "semantic-ui-react";

export default class Footer extends Component {
    state = {activeItem: "home"};

    handleItemClick = (e, {name}) => this.setState({activeItem: name});

    render() {
        const {activeItem} = this.state;

        return (
            <Segment
                inverted
                vertical
                style={{margin: "1em 0em 0em", padding: "1em 0em"}}
            >
                <Container textAlign="center">ZETACRON Development © 2020</Container>
            </Segment>
        );
    }
}
