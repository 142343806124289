import React, {Component, Fragment} from "react";
import {Card, Icon} from "semantic-ui-react";

class BodyFragment extends Component {
    state = {};

    render() {
        return (
            <Card color="green" fluid style={{height: "100%"}}>
                <Card.Content>
                    <Card.Header color="blue">
                        {this.props.icon ? (
                            <Fragment>
                                <Icon name={this.props.icon}></Icon> {this.props.title}
                            </Fragment>
                        ) : (
                            this.props.title
                        )}
                    </Card.Header>
                </Card.Content>
                <Card.Content
                    style={{
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            margin: 0,
                            padding: 5,
                            paddingTop: 10,
                            overflowX: "hidden",
                            overflowY: "auto",
                        }}
                    >
                        {this.props.children}
                    </div>
                </Card.Content>
            </Card>
        );
    }
}

export default BodyFragment;
