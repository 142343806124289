import React, {Component} from "react";
import {Button, Divider, Form, Icon, Input, Message} from "semantic-ui-react";
import BodyFragment from "../module/BodyFragment";
import ReCAPTCHA from "react-google-recaptcha";
import MaskedInput from "react-text-mask";
import Api from "../helper/Api";
import Translate from "../component/localization/Translate";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            uploadType: "link",
            captchaKey: null,
            form: {},
            errors: [],
            confirmed: false,
        };
        this.macAddress = React.createRef();
        this.deviceId = React.createRef();
        this.link = React.createRef();
        this.file = React.createRef();
    }

    handleChangeForUploadType = (e, {value}) => {
        this.setState({uploadType: value})
    };
    handleChangeForConfirm = (e, data) => {
        this.setState({confirmed: data.checked})
    };
    handleChange = (e, val) => {
        let form = this.state.form;
        if (e.target.type === "file") {
            form[e.target.name] = e.target.files[0];
        } else {
            form[e.target.name] = e.target.value;
        }
        this.setState({form: form});
    };

    handleChangeForAddToList = (e, {value}) => {
        let form = this.state.form;
        form.newList = value;
        this.setState({isNewList: value, form: form});
    };

    onCaptchaChange = (value) => this.setState({captchaKey: value});
    upload = () => {
        let errors = [];
        let macRegex = new RegExp(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/gm);
        if (
            this.state.form.macAddress === undefined ||
            !macRegex.test(this.state.form.macAddress)
        ) {
            errors.push(Translate.insertMacAddress);
        }
        if (
            this.state.form.deviceId === undefined ||
            this.state.form.deviceId.length === 0
        ) {
            errors.push(Translate.insertDeviceId);
        }
        if (this.state.uploadType === "link") {
            let urlRegex = new RegExp(
                /^(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
            );
            if (
                this.state.form.link === undefined ||
                this.state.form.link.length === 0
            ) {
                errors.push(Translate.insertUrl);
            } else if (!urlRegex.test(this.state.form.link)) {
                errors.push(Translate.insertCorrectUrl);
            }
        }
        if (this.state.uploadType === "file") {
            if (this.state.form.file === null || this.state.form.file === undefined) {
                errors.push(Translate.selectM3uFile);
            } else if (this.state.form.file.name.indexOf(".m3u") === -1) {
                errors.push(Translate.selectCorrectHLSFile);
            }
        }
        if (this.state.captchaKey === null) {
            errors.push(Translate.verifyCaptcha);
        }
        if (!this.state.confirmed) {
            errors.push(Translate.acceptCaution);
        }
        this.setState({errors: errors});
        if (errors.length === 0) {
            this.setState({errors: [], loading: true});

            Api.form("/device/add", this.state.form).then(
                (response) => {
                    if (response && response.success) {

                        this.setState({success: response.success, countChannels: response.data, errors: [], loading: false});
                        this.macAddress.current.inputElement.value = "";
                        this.deviceId.current.inputRef.current.value = "";
                        if (this.link.current) {
                            this.link.current.inputRef.current.value = "";
                        }
                        if (this.file.current) {
                            this.file.current.inputRef.current.value = "";
                        }
                    } else {
                        this.setState({errors: [Translate.noContainsHLSList], loading: false});
                    }
                }
            );
        }
    };

    componentDidMount() {
        document.title = Translate.title;
    }

    componentWillReceiveProps() {
        document.title = Translate.title;
    }

    render() {
        return (
            <BodyFragment
                title={
                    <div>
                        <Icon name="upload"/> {Translate.formTitle}
                        {Translate.how}
                    </div>
                }
            >
                <Message
                    warning
                    icon="warning"
                    header={Translate.caution}
                    content={Translate.cautionMessage}
                />

                <Divider/>

                {this.state.success ? (
                    <Message
                        success
                        icon="check"
                        header={Translate.deviceAddedTitle}
                        content={Translate.formatString(Translate.deviceAddedDesc, this.state.countChannels)}
                    />
                ) : null}
                <Form style={{padding: 0, margin: 0}}>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>{Translate.macAddress}</label>
                            <MaskedInput
                                label="Mac Address"
                                ref={this.macAddress}
                                name="macAddress"
                                placeholder="Format: 1A:2B:3C:4D:5E:6:F"
                                onChange={this.handleChange}
                                mask={[
                                    /[0-9A-Fa-f]/,
                                    /[0-9A-Fa-f]/,
                                    ":",
                                    /[0-9A-Fa-f]/,
                                    /[0-9A-Fa-f]/,
                                    ":",
                                    /[0-9A-Fa-f]/,
                                    /[0-9A-Fa-f]/,
                                    ":",
                                    /[0-9A-Fa-f]/,
                                    /[0-9A-Fa-f]/,
                                    ":",
                                    /[0-9A-Fa-f]/,
                                    /[0-9A-Fa-f]/,
                                    ":",
                                    /[0-9A-Fa-f]/,
                                    /[0-9A-Fa-f]/,
                                ]}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>{Translate.deviceId}</label>
                            <Input
                                ref={this.deviceId}
                                name="deviceId"
                                control="input"
                                onChange={this.handleChange}
                                placeholder="Format: a78aa8d0dd06aaf3"
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Radio
                            label={Translate.file}
                            value="file"
                            checked={this.state.uploadType === "file"}
                            onChange={this.handleChangeForUploadType}
                        />
                        <Form.Radio
                            label={Translate.link}
                            value="link"
                            checked={this.state.uploadType === "link"}
                            onChange={this.handleChangeForUploadType}
                        />
                    </Form.Group>
                    {this.state.uploadType === "link" ? (
                        <Form.Field>
                            <label>{Translate.link}</label>
                            <Input
                                ref={this.link}
                                onChange={this.handleChange}
                                name="link"
                                control="input"
                                placeholder="http://"
                            />
                        </Form.Field>
                    ) : (
                        <Form.Field>
                            <Input
                                name="file"
                                type="file"
                                ref={this.file}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    )}

                    <Form.Group grouped={true} widths='equal'>
                        <Form.Radio
                            label={Translate.asNewList}
                            name={"isNewList"}
                            value={true}
                            checked={this.state.isNewList}
                            onChange={this.handleChangeForAddToList}
                        />
                        <Form.Radio
                            label={Translate.addToActiveList}
                            name="isNewList"
                            value={false}
                            checked={this.state.isNewList === undefined || this.state.isNewList === false}
                            onChange={this.handleChangeForAddToList}
                        />
                    </Form.Group>

                    <Divider/>

                    <Form.Checkbox
                        label={Translate.confirm}
                        onChange={this.handleChangeForConfirm}
                    />

                    <Form.Field>
                        <label>{Translate.captcha}</label>
                        <ReCAPTCHA
                            sitekey="6LeAA7EZAAAAAFfX_nFBhqyFO0OH5ElvunnZkOBc"
                            onChange={this.onCaptchaChange}
                        />
                    </Form.Field>
                    {this.state.errors.length > 0 ? (
                        <Message negative>
                            <Message.Header>{Translate.error}</Message.Header>
                            <ul>
                                {this.state.errors.map((error) => {
                                    return <li>{error}</li>;
                                })}
                            </ul>
                        </Message>
                    ) : (
                        ""
                    )}

                    <Button
                        loading={this.state.loading}
                        type="submit"
                        color="green"
                        onClick={() => this.upload()}
                    >
                        {Translate.submit}
                    </Button>
                    <Divider hidden/>
                </Form>
            </BodyFragment>
        );
    }
}

export default Index;
