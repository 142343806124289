import LocalizedStrings from "react-localization";

let Translate = new LocalizedStrings({
    en: {
        home: "Home",
        download: "Download",
        title: "Load HLS(m3u8) IPTV List | ZETACRON IPTV Player",
        formTitle: "Add HLS(m3u8) list for your ZETACRON IPTV installed device",
        caution: "Caution!",
        cautionMessage: "We remind you that you are responsible for the content (illegal, obscene, 18+ etc.) you have uploaded to your device, and indicate that ZETACRON cannot control and block this content, and that all problems that may arise belong to the user.",
        deviceAddedTitle: "Successful",
        deviceAddedDesc: "{0} channels were detected in the HLS list and set up for the first connection of your device." +
            "Please click the reload button in the application or restart the application",
        macAddress: "Mac Address",
        insertMacAddress: "Please insert a Mac Address.",
        insertDeviceId: "Please insert a Device Id.",
        insertUrl: "Please insert a URL.",
        insertCorrectUrl: "Please insert a correct URL",
        selectM3uFile: "Please select a HLS(m3u8) file.",
        selectCorrectHLSFile: "Please select a correct HLS(m3u8) file.",
        verifyCaptcha: "Please verify the captcha!.",
        deviceId: "Device Id",
        link: "Link",
        file: "File",
        captcha: "Captcha",
        submit: "Submit",
        error: "Error",
        errorTitle: "An error occurred",
        noContainsHLSList: "The link you are trying to upload does not contain a valid HLS list",
        confirm: "I have read and accept the above warning.",
        acceptCaution: "Please indicate that you accept the caution",
        logout: "Logout",
        devices: "Devices",
        device: "Device",
        description: 'Description',
        source: 'Source',
        deviceManagement: "Device Management",
        lastLogin: 'Last Login',
        special: 'Special',
        edit: 'Edit',
        new: 'New',
        delete: 'Delete',
        deleteConfirm: 'Are you sure to delete this?',
        deleteAllConfirm: 'Are you sure to delete all?',
        deleteMultiConfirm: 'Are you sure to delete selected items?',
        loading: 'Loading...',
        processing: 'Processing...',
        save: 'Save',
        cancel: 'Cancel',
        username: 'Username',
        password: 'Password',
        login: 'Login',
        active: 'Active',
        loginDescription: 'Please login with your username and password',
        asNewList: 'Add as new List to device',
        addToActiveList: 'Add to active list on device',
        downloaded: 'Downloaded'
    },
    tr: {
        home: "Anasayfa",
        download: "Download",
        title: "HLS(m3u8) IPTV listesi yükle | ZETACRON IPTV Player",
        formTitle: "ZETACRON IPTV kurulu cihazınız için HLS(m3u8) listesi ekleyin",
        caution: "Dikkat!",
        cautionMessage: "Cihazınıza yüklediğiniz içerikten(illegal, müstehcen, 18+ vb) sizin sorumlu olduğunuzu hatırlatır, ZETACRON'un bu içeriği denetleme ve engellemesinin mümkün olmadığını, oluşabilecek tüm problemlerin kullanıcıya ait olduğunu belirtiriz.",
        deviceAddedTitle: "Başarılı",
        deviceAddedDesc: "HLS listesinde {0} kanal tespit edildi ve cihazınızın ilk bağlantısı için ayarlandı. " +
            "Lütfen uygulamada yeniden yükle düğmesine tıklayın veya uygulamayı yeniden başlatın.",
        macAddress: "Mac Adresi",
        insertMacAddress: "Lütfen bir Mac Adresi girin.",
        insertDeviceId: "Lütfen bir Cihaz Kimliği girin.",
        insertUrl: "Lütfen bir URL girin.",
        insertCorrectUrl: "Lütfen geçerli bir URL girin",
        selectM3uFile: "Lütfen bir HLS(m3u8) dosyası seçin.",
        selectCorrectHLSFile: "Lütfen geçerli bir HLS(m3u8) dosyası seçin.",
        verifyCaptcha: "Lütfen güvenlik adımını doğrulayın !.",
        deviceId: "Cihaz Kimliği",
        link: "Link",
        file: "Dosya",
        captcha: "Güvenlik adımı",
        submit: "Gönder",
        error: "Hata",
        errorTitle: "Bir hata oluştu",
        noContainsHLSList: "Yüklemeye çalıştığınız bağlantı geçerli bir HLS listesi içermiyor",
        confirm: "Yukarıdaki uyarıyı okudum ve kabul ediyorum.",
        acceptCaution: "Lütfen uyarıyı kabul ettiğinizi belirtin",
        logout: "Oturumu Kapat",
        devices: "Cihazlar",
        device: "Cihaz",
        description: 'Açıklama',
        source: 'Kaynak',
        deviceManagement: "Cihaz Yönetimi",
        lastLogin: 'Son Erişim',
        special: 'Özel',
        edit: 'Düzenle',
        new: 'Yeni',
        delete: 'Sil',
        deleteConfirm: 'Bunu silmek istediğinize emin misiniz?',
        loading: 'Yükleniyor...',
        processing: 'İşleniyor...',
        save: 'Kaydet',
        cancel: 'İptal',
        username: 'Kullanıcı adı',
        password: 'Parola',
        login: 'Oturum Aç',
        active: 'Aktif',
        loginDescription: 'Lütfen kullanıcı adı ve parolanızla oturum açın.',
        asNewList: 'Cihaza yeni liste olarak ekle',
        addToActiveList: 'Cihazdaki aktif listeye ekle',
        downloaded: 'Indirildi'
    },
    de: {
        home: "Startseite",
        download: "Download",
        title: "Laden HLS(m3u8) IPTV-Liste | ZETACRON IPTV Player?",
        formTitle: "Fügen Sie eine HLS(m3u8)-Liste für Ihr installiertes ZETACRON IPTV-Gerät hinzu",
        caution: "Vorsicht!",
        cautionMessage: "Wir erinnern Sie daran, dass Sie für den Inhalt (illegal, obszön, 18+ usw.) verantwortlich sind, den Sie auf Ihr Gerät hochgeladen haben, und weisen darauf hin, dass ZETACRON diesen Inhalt nicht kontrollieren und blockieren kann und dass alle auftretenden Probleme dem Benutzer gehören.",
        deviceAddedTitle: "Erfolgreich",
        deviceAddedDesc: "{0} Kanäle wurden in der HLS-Liste erkannt und für die erste Verbindung Ihres Geräts eingerichtet. " +
            "Bitte klicken Sie in der App auf die Schaltfläche zum erneuten Laden oder die App neu starten",
        macAddress: "MAC-Adresse",
        insertMacAddress: "Bitte geben Sie eine Mac-Adresse ein.",
        insertDeviceId: "Bitte geben Sie eine Geräte-ID ein.",
        insertUrl: "Bitte geben Sie eine URL ein.",
        insertCorrectUrl: "Bitte geben Sie eine korrekte URL ein",
        selectM3uFile: "Bitte wählen Sie eine HLS(m3u8)-Datei.",
        selectCorrectHLSFile: "Bitte wählen Sie eine korrekte HLS(m3u8)-Datei.",
        verifyCaptcha: "Bitte überprüfen Sie das Captcha!.",
        deviceId: "Geräte ID",
        link: "Link",
        file: "Datei",
        captcha: "Captcha",
        submit: "Laden",
        error: "Fehler",
        errorTitle: "Ein Fehler ist aufgetreten",
        noContainsHLSList: "Der Link, den Sie hochladen möchten, enthält keine gültige HLS-Liste",
        confirm: "Ich habe die obige Vorsicht gelesen und akzeptiere sie.",
        acceptCaution: "Bitte geben Sie an, dass Sie die Vorsicht akzeptieren.",
        logout: "Logout",
        devices: "Devices",
        device: "Device",
        description: 'Description',
        source: 'Source',
        deviceManagement: "Device Management",
        lastLogin: 'Last Login',
        special: 'Special',
        edit: 'Edit',
        new: 'New',
        delete: 'Delete',
        deleteConfirm: 'Are you sure to delete this?',
        loading: 'Loading...',
        processing: 'Processing...',
        save: 'Save',
        cancel: 'Cancel',
        username: 'Username',
        password: 'Password',
        login: 'Login',
        active: 'Active',
        loginDescription: 'Please login with your username and password',
        asNewList: 'Als neue Liste zum Gerät hinzufügen',
        addToActiveList: 'Zur aktiven Liste beim Gerät hinzufügen',
        downloaded: 'Heruntergeladen'
    },
});

export default Translate;