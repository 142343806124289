import React, {Component} from "react";
import {Modal} from "semantic-ui-react";

class Dialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            size: this.props.size,
        };
    }

    static getDerivedStateFromProps(props) {
        return ({open: props.open});
    }

    handleClose = () => {
        this.setState({open: false})
        if (this.props.onClose && typeof this.props.onClose === "function") {
            this.props.onClose();
        }
    };

    render() {
        const {open, size} = this.state;

        return (
            <Modal
                //dimmer="blurring"
                size={size}
                open={open}
                className={this.props.className}
                closeIcon
                onClose={this.handleClose}
                closeOnEscape={true}
                closeOnDimmerClick={false}
            >
                <Modal.Header>{this.props.header}</Modal.Header>
                <Modal.Content scrolling>{this.props.children}</Modal.Content>
                <Modal.Actions>{this.props.footer}</Modal.Actions>
            </Modal>
        );
    }
}

export default Dialog;
