import React, {Component} from "react";
import {ReactSortable} from "react-sortablejs";
import {Icon, Input, List} from "semantic-ui-react";
import Generator from "../helper/Generator";
import Util from "../helper/Util";

class CreateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            value: "",
            name: this.props.name,
            items: this.convertValuesToItems(this.props.values) || [],
        };

        this.inputRef = React.createRef();
    }

    convertValuesToItems = (values) => {
        if (values) {
            const items = [];
            values.forEach((value) => {
                items.push({value: value, key: Generator.getUUID()});
            });

            return items;
        }
        return null;
    };
    handleAddItem = () => {
        if (this.state.value.length === 0) {
            this.setState({
                error: {content: "The value can't be empty!"},
            });
            return;
        }
        if (this.state.items.find((item) => item.value === this.state.value)) {
            this.setState({
                error: {content: "This value already exists!"},
            });
            return;
        }

        if (this.props.mask) {
            const regex = new RegExp(this.props.mask);
            if (!this.state.value.match(regex)) {
                this.setState({
                    error: {content: "This value isn't correct!"},
                });
                return;
            }
        }
        let items = this.state.items.concat([
            {value: this.state.value, key: Generator.getUUID()},
        ]);
        this.setState({
            items: items,
            value: "",
            error: null,
        });
        if (Util.isFunction(this.props.onChange)) {
            this.props.onChange(null, {
                name: this.props.name,
                value: items.map((item) => {
                    if (this.props.subName) {
                        return {[this.props.subName]: item.value}
                    } else {
                        return item.value
                    }
                }),
            });
        }
    };

    handleKeyDown = (event) => {
        event.stopPropagation();
        if (event.key === "Enter") {
            this.handleAddItem();
        }
    };

    handleChangeValue = (e) => {
        this.setState({value: e.target.value});
    };

    handleDeleteItem = (value) => {
        const newList = this.state.items.filter((item) => {
            return item !== value;
        });
        this.setState({
            items: newList,
        });
        this.handleNewList(newList);
    };

    handleNewList = (newList) => {
        if (JSON.stringify(this.state.items) === JSON.stringify(newList)) {
            return;
        }
        this.setState({items: newList});
        if (this.props.onChange && typeof this.props.onChange === "function") {
            this.props.onChange(null, {
                name: this.props.name,
                value: newList.map((item) => {
                    if (this.props.subName) {
                        return {[this.props.subName]: item.value}
                    } else {
                        return item.value
                    }

                }),
            });
        }
    };

    render() {
        return (
            <div class="create-list">
                <Input
                    type="text"
                    onKeyDown={this.handleKeyDown}
                    value={this.state.value}
                    ref={this.inputRef}
                    onChange={this.handleChangeValue}
                    error={this.state.error}
                    placeholder="Insert Source"
                    icon={
                        <Icon
                            name="add"
                            color="green"
                            onClick={this.handleAddItem}
                            inverted
                            circular
                            link
                        />
                    }
                    fluid
                />

                <List divided verticalAlign="middle">
                    <ReactSortable
                        multiDrag
                        multiDragKey="ctrl"
                        selectedClass="chosen-item"
                        ghostClass="ghost-item"
                        group={this.props.group || Generator.getUUID()}
                        animation={200}
                        delayOnTouchStart={true}
                        list={this.state.items}
                        handle=".handle"
                        setList={this.handleNewList}
                        delay={2}
                    >
                        {this.state.items.map((item, index) => {
                            return (
                                <List.Item style={{padding: 2}}>
                                    <Input
                                        key={item.key}
                                        type="text"
                                        name={this.props.subName ? this.props.name + '[][' + this.props.subName + ']' : this.props.name}
                                        value={item.value}
                                        icon={
                                            <Icon
                                                name="trash"
                                                color="red"
                                                onClick={() => this.handleDeleteItem(item)}
                                                inverted
                                                circular
                                                link
                                            />
                                        }
                                        fluid
                                        readOnly
                                        actionPosition="left"
                                        action={{
                                            color: "green",
                                            className: "handle",
                                            icon: "move",
                                        }}
                                    />
                                </List.Item>
                            );
                        })}
                    </ReactSortable>
                </List>
            </div>
        );
    }
}

export default CreateList;
