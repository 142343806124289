import Translate from "../component/localization/Translate";

const TableConfig = {
    device: {
        apiURL: '/device',
        filterConfig: {
            page: 1,
            size: 50,
            sort: 'id',
            order: 'desc',
            filters: []
        },

        columns: [
            {
                name: "id",
                caption: "ID",
                sortable: true,
                filterable: true,
                width: "80px",
                filterConfig: {
                    input: {
                        type: "text"
                    },
                    operator: "CONTAINS"
                }

            }, {
                name: "macAddress",
                caption: Translate.macAddress,
                width: "50px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "text"
                    },
                    operator: "CONTAINS"
                }
            }, {
                name: "deviceId",
                caption: Translate.deviceId,
                width: "50px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "text"
                    },
                    operator: "CONTAINS"
                }
            }, {
                name: "source",
                caption: Translate.source,
                width: "140px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "text"
                    },
                    operator: "CONTAINS"
                }
            }, {
                name: "description",
                caption: Translate.description,
                width: "100px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "text"
                    },
                    operator: "CONTAINS"
                }
            }, {
                name: "lastLogin",
                caption: Translate.lastLogin,
                width: "80px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "date"
                    },
                    operator: "EQUALS"
                }
            }, {
                name: "ip",
                caption: "IP",
                width: "80px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "text"
                    },
                    operator: "CONTAINS"
                }
            }, {
                name: "sourceDownloaded",
                caption: Translate.downloaded,
                width: "40px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "checkbox"
                    },
                    operator: "EQUALS"
                }
            }, {
                name: "special",
                caption: Translate.special,
                width: "40px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "checkbox"
                    },
                    operator: "EQUALS"
                }
            }, {
                name: "premium",
                caption: "Premium",
                width: "40px",
                sortable: true,
                filterable: true,
                filterConfig: {
                    input: {
                        type: "checkbox"
                    },
                    operator: "EQUALS"
                }
            }
        ],
        rows: []
    },
};
export default TableConfig;
