import React, {Component} from "react";
import {Button, Container} from "semantic-ui-react";
import BodyFragment from "../module/BodyFragment";
import CreateForm from "../component/CreateForm";
import Api from "../helper/Api";
import Session from "../helper/Session";
import FormConfig from "../config/FormConfig";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit = (inputs) => {
        Api.post("/device/login", inputs).then(response => {
            if (response.success) {
                Session.set("authentication", inputs);
                if (this.props.handleLogin) {
                    this.props.handleLogin(inputs);
                }
            }
        });
    };

    render() {
        return (
            <BodyFragment title="Login" icon="tv">
                <CreateForm
                    config={FormConfig.login}
                    handleSubmit={this.handleSubmit}
                    submitButton={
                        <Button floated="right" color="blue" submit>
                            Login
                        </Button>
                    }
                />
            </BodyFragment>
        );
    }
}

export default Login;
