import Translate from "../component/localization/Translate";

const FormConfig = {
    login: {
        columns: 2,
        groups: [
            {
                key: 1,
                title: Translate.login,
                name: "login",
                description: Translate.loginDescription,
                style: {maxWidth: '400px', margin: 'auto'},
                fields: [
                    {
                        name: "username",
                        label: Translate.username,
                        placeholder: Translate.username,
                        icon: "user",
                        type: "text",
                        validations: [{mask: /^.{3,}$/, message: "Required Min 3 Characters!"}],
                        required: false,
                    }, {
                        name: "password",
                        label: Translate.password,
                        placeholder: Translate.password,
                        validations: [{mask: /^.{3,}$/, message: "Required Min 3 Characters!"}],
                        icon: {name: "key", position: "left"},
                        type: "password",
                        required: false,
                    }
                ],
            },
        ],
    },

    device: {
        postUrl: "/device",
        getUrl: "/device",
        deleteUrl: "/device",
        columns: 1,
        groups: [
            {
                key: 1,
                title: Translate.device,
                description: "",
                fields: [
                    {
                        name: "macAddress",
                        label: Translate.macAddress,
                        type: "text",
                        required: true,
                        placeholder: Translate.macAddress,
                    }, {
                        name: "deviceId",
                        label: Translate.deviceId,
                        type: "text",
                        required: true,
                        placeholder: Translate.deviceId,
                    }, {
                        name: "source",
                        label: Translate.source,
                        type: "text",
                        required: true,
                        placeholder: Translate.source,
                        validations: [
                            {
                                mask: /^(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
                                message: "URL is incorrect!"
                            }
                        ],
                    }, {
                        name: "description",
                        label: Translate.description,
                        type: "textarea",
                        required: false,
                    }, {
                        name: "sourceDownloaded",
                        label: "Downloadable",
                        type: "checkbox",
                        required: false,
                    },{
                        name: "premium",
                        label: "Premium",
                        type: "checkbox",
                        required: false,
                    }, {
                        name: "special",
                        label: Translate.special,
                        type: "checkbox",
                        required: false,
                    }, {
                        name: "active",
                        label: Translate.active,
                        type: "checkbox",
                        required: false,
                    }
                ],
            },
        ],
    },
};
export default FormConfig;
