import React, {Component} from "react";
import Dialog from "./Dialog";
import {Button, Icon} from "semantic-ui-react";

class ConfirmDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            size: this.props.size,
            data: this.props.data
        };
    }

    handleConfirm = () => {
        if (
            this.props.handleConfirm &&
            typeof this.props.handleConfirm === "function"
        ) {
            this.props.handleConfirm(this.state.data);
        }
    };

    static getDerivedStateFromProps(props) {
        return ({open: props.open});
    }

    handleClose = () => {
        this.setState({open: false});
        if (this.props.handleClose && typeof this.props.handleClose === "function") {
            this.props.handleClose();
        }
    };

    render() {
        const {open} = this.state;

        return (
            <Dialog
                size="mini"
                onClose={this.handleClose}
                open={open}
                header={
                    <React.Fragment>
                        <Icon name="question"/>
                        {this.props.title}
                    </React.Fragment>
                }
                footer={
                    <React.Fragment>
                        <Button color="red" onClick={() => this.handleClose()}>
                            <Icon name="remove"/> No
                        </Button>
                        <Button color="green" onClick={this.handleConfirm}>
                            <Icon name="checkmark"/> Yes
                        </Button>
                    </React.Fragment>
                }
            >
                {this.props.children}
            </Dialog>
        );
    }
}

export default ConfirmDialog;
