import React, {Component} from "react";
import {Grid} from "semantic-ui-react";
import FormField from "./FormField";

class MultiCheckbox extends Component {
    constructor(props) {
        super(props);

        const checkboxes = [];
        if (this.props.values && this.props.values.length > 0) {
            //Checkboxes Default Values
            this.props.values.map((value) => {
                checkboxes.push({value: value});
            });
            if (this.props.onChange && typeof this.props.onChange === "function") {
                this.props.onChange(null, {
                    value: checkboxes.map((checkbox) => checkbox.value),
                });
            }
        }

        this.state = {
            options: this.props.options || [],
            name: this.props.name,
            checkboxes: checkboxes,
        };
    }

    handleChange = (e, obj) => {
        let checkboxes = [];
        const findValue = this.state.checkboxes.find(
            (checkbox) => checkbox.value === obj.value
        );
        if (!findValue) {
            checkboxes = this.state.checkboxes.concat([{value: obj.value}]);
            this.setState({checkboxes: checkboxes});
            this.props.onChange(e, {value: checkboxes.map((item) => item.value)});
        } else {
            checkboxes = this.state.checkboxes.filter((checkbox) => {
                return checkbox.value !== obj.value;
            });

            this.setState({
                checkboxes: checkboxes,
            });
        }
        if (this.props.onChange && typeof this.props.onChange === "function") {
            this.props.onChange(e, {
                value: checkboxes.map((checkbox) => checkbox.value),
            });
        }
    };

    render() {
        const {...props} = this.props;

        return (
            <div>
                <Grid style={{marginTop: 10}}>
                    {this.state.options.map((option, index) => {
                        return (
                            <Grid.Row columns={3} key={index} style={{padding: 5}}>
                                <Grid.Column>{option.text}</Grid.Column>
                                <Grid.Column>
                                    <FormField
                                        key={option.key}
                                        type="checkbox"
                                        name={this.props.name}
                                        value={option.value}
                                        defaultChecked={
                                            this.props.values &&
                                            this.props.values.find((value) => value === option.value)
                                        }
                                        {...props}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })}
                </Grid>
            </div>
        );
    }
}

export default MultiCheckbox;
