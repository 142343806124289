import React from "react";
import GlobalContext from "./GlobalContext";
import Translate from "../localization/Translate";

class AppProvider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            translate: Translate
        };

    }

    render() {
        const {translate} = this.state;
        return (
            <GlobalContext.Provider
                value={{
                    translate
                }}
            >
                {this.props.children}
            </GlobalContext.Provider>
        );
    }
}

export default AppProvider;
