import React, {Component} from "react";
import BodyFragment from "../module/BodyFragment";
import DataTable from "../component/DataTable";
import TableConfig from "../config/TableConfig";
import {Icon} from "semantic-ui-react";
import FormField from "../component/FormField";
import FormConfig from "../config/FormConfig";
import Translate from "../component/localization/Translate";

class Manage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
        this.datatable = React.createRef();
    }

    rowTemplate = (entity) => {
        return {
            id: {value: entity.id, text: entity.id},
            macAddress: {text: entity.macAddress, value: entity.macAddress},
            deviceId: {text: entity.deviceId, value: entity.deviceId},
            source: {
                text: <FormField type="text" style={{width: "100%"}} key={entity.source} value={entity.source}
                                 readOnly/>,
                value: entity.source
            },
            description: {
                text: entity.description,
                value: entity.description
            },
            lastLogin: {
                text: entity.lastLogin,
                value: entity.lastLogin
            },
            ip: {text: entity.ip, value: entity.ip},
            sourceDownloaded: {
                text: entity.sourceDownloaded ? <Icon color={"green"} name="check"/> :
                    <Icon color={"red"} name="times circle"/>,
                value: entity.sourceDownloaded
            },
            special: {
                text: entity.special ? <Icon color={"green"} name="check"/> :
                    <Icon color={"red"} name="times circle"/>,
                value: entity.special
            },
            premium: {
                text: entity.premium ? <Icon color={"green"} name="check circle"/> :
                    <Icon color={"red"} name="times circle"/>,
                value: entity.premium
            }
        }
    };

    render() {
        return (
            <>
                <BodyFragment title={Translate.deviceManagement} icon="android">
                    <DataTable
                        ref={this.datatable}
                        rowTemplate={this.rowTemplate}
                        tableConfig={TableConfig.device}
                        formConfig={FormConfig.device} endlessScrolling/>
                </BodyFragment>

            </>
        );
    }
}

export default Manage;
